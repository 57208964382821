.Landing {
  text-align: center;
  width: 70%;
}
.Landing h1 {
  font-weight: bold;
  font-style: italic;
  font-size: 48px;
  color: rgb(30, 34, 83);
  margin-right: 15px;
  letter-spacing: 3;
}
@media only screen and (max-width: 768px) {
  .Landing h1 {
    font-size: 30px;
  }
}

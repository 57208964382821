.button_container {
  display: flex;
  margin-left: 5px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 80%;
}

.button {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  transition: all ease-in 0.2s;
}

.button:hover {
  background-color: rgb(73, 73, 72);
  transform: scale(1.1);
}

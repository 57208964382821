.why_us {
  margin-top: 80px;
}
.heading {
  color: rgb(30, 34, 83);
  font-size: 38px;
  font-style: italic;
  text-align: center;
  width: 100%;
}
.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  gap: 10%;
  margin: 50px;
  padding: 50px;
  transform: translate(5%);
}
.grid_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid_item h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: rgb(55, 71, 79);
}
.grid_item p {
  text-align: center;
  color: rgb(66, 75, 80);
}

.img {
  background-color: rgb(15, 71, 162);
  height: 75px;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.img i {
  color: white;
}
@media only screen and (max-width: 768px) {
  .grid_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    transform: translate(0);
  }
  .grid_item {
    margin-top: 15px;
  }
}

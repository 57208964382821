.heading {
  color: rgb(30, 34, 83);
  font-size: 38px;
  font-style: italic;
  text-align: center;
  width: 100%;
}

.icon_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.icon {
  color: white;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(30, 34, 83);
}

.info {
  color: rgb(66, 75, 80);
  width: 80%;
  margin-left: 10px;
  font-size: 20px;
}
.accordion_section {
  display: flex;
  flex-direction: column;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion:hover,
.active {
  background-color: #ccc;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0.2);
}

.accordion_title {
  font-weight: bold;
  font-size: 20px;
  color: rgb(30, 34, 83);
}

.accordion_icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion_content {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  gap: 15px;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion_text {
  font-size: 14px;
  padding: 18px;
}

.img_list_container {
  margin-left: 15px;
  margin-top: 20px;
}
.content {
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in;
}
.content:hover {
  transform: scale(1.5);
}

@media only screen and (max-width: 768px) {
  .accordion_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content:hover{
    transform:none;
  }
}
